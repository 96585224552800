<template>
  <div>
    <h4 class="mb-5">{{ $t("message.customer-page-title") }}</h4>
    <v-form @submit.prevent="searchCustomer()">
      <v-row no-gutters align="center" justify="space-between">
        <v-col cols="12" md="8">
          <v-card class="d-flex flex-wrap" flat tile>
            <v-col md="3" xs="4" style="padding-top: 0px; padding-bottom: 0px">
              <span>{{ $t("message.customer-id") }}</span>
              <v-text-field
                v-model="filter.customerId"
                type="text"
                outlined
                clearable
                class="mt-1"
              ></v-text-field>
            </v-col>
            <v-col md="4" xs="4" style="padding-top: 0px; padding-bottom: 0px">
              <span>{{ $t("message.customer-name") }}</span>
              <v-text-field
                type="text"
                outlined
                clearable
                v-model="filter.customerName"
                class="mt-1"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="5"
              style="padding-top: 0px; padding-bottom: 0px"
            >
              <span>{{ $t("message.last-order-date") }}</span>
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="filter.date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filter.date"
                    class="mt-1"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    clearable
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="filter.date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    {{ $t("message.cancel") }}
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(filter.date)">
                    {{ $t("message.ok") }}
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col md="5" xs="4" style="padding-top: 0px; padding-bottom: 0px">
              <span>{{ $t("message.time-use") }}</span>
              <v-row no-gutters class="row-amount">
                <v-col md="5" xs="2">
                  <v-text-field
                    class="mt-1"
                    type="number"
                    outlined
                    clearable
                    v-model="filter.timeuseStart"
                  ></v-text-field>
                </v-col>
                <v-col class="minus-amount">
                  <v-icon class="icon-tilde-class">mdi-tilde</v-icon>
                </v-col>
                <v-col md="5" xs="2">
                  <v-text-field
                    type="number"
                    outlined
                    clearable
                    class="mt-1"
                    v-model="filter.timeuseEnd"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="6" xs="4" style="padding-top: 0px; padding-bottom: 0px">
              <span>{{ $t("message.total-amount") }}</span>
              <v-row no-gutters class="row-amount ">
                <v-col md="5" xs="2">
                  <v-text-field
                    class="mt-1"
                    v-model="filter.amountStart"
                    outlined
                    clearable
                  />
                </v-col>
                <v-col class="minus-amount">
                  <v-icon class="icon-tilde-class">mdi-tilde</v-icon>
                </v-col>
                <v-col md="5" xs="2">
                  <v-text-field
                    class="mt-1"
                    v-model="filter.amountEnd"
                    outlined
                    clearable
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>

        <v-col cols="12" md="4" style="padding-top: 0px; padding-bottom: 0px">
          <v-row class="d-flex" justify="end">
            <v-col cols="12" md="6" xs="4" style="width: fit-content">
              <v-btn type="submit" rounded color="primary" block @click.prevent="searchCustomer()">
                <v-icon left color="white">
                  mdi-magnify
                </v-icon>
                {{ $t("message.search") }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" style="width: fit-content; padding-left: 0px">
              <v-btn rounded color="primary" block @click.prevent="print()">
                <v-icon left>
                  mdi-export-variant
                </v-icon>
                {{ $t("message.download") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "FilterCustomer",
  data: () => ({
    menu: false,
    modal: false,
    menu2: false,
    clearable: true,
    valueWhenIsEmpty: "",
    options: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 11,
      precision: 0,
    },
    filter: {
      date: "",
      customerId: "",
      customerName: "",
      timeuseStart: "",
      timeuseEnd: "",
      amountStart: "",
      amountEnd: "",
    },
  }),

  methods: {
    fetchCustomerList() {
      if (!this.filter.customerId) this.filter.customerId = "";
      if (!this.filter.customerName) this.filter.customerName = "";
      if (!this.filter.timeuseStart) this.filter.timeuseStart = "";
      if (!this.filter.timeuseEnd) this.filter.timeuseEnd = "";
      if (!this.filter.amountStart) this.filter.amountStart = "";
      if (!this.filter.amountEnd) this.filter.amountEnd = "";
      this.$store.dispatch("customer_module/fetchCustomerList", {
        customerId: this.filter.customerId === null ? "" : this.filter.customerId,
        customerName: this.filter.customerName === null ? "" : this.filter.customerName,
        timeuseStart: this.filter.timeuseStart === null ? "" : this.filter.timeuseStart,
        timeuseEnd: this.filter.timeuseEnd === null ? "" : this.filter.timeuseEnd,
        amountEnd: this.filter.amountEnd === null ? "" : this.filter.amountEnd,
        amountStart: this.filter.amountStart === null ? "" : this.filter.amountStart,
        lastOrderDate: this.filter.date === null ? "" : this.filter.date,
        page: "",
      });
    },
    initFilter(){
      this.filter.customerId = this.$store.state.customer_module.filter.customerId
      this.filter.customerName =this.$store.state.customer_module.filter.customerName,
      this.filter.timeuseStart = this.$store.state.customer_module.filter.timeuseStart,
      this.filter.timeuseEnd = this.$store.state.customer_module.filter.timeuseEnd,
      this.filter.amountStart = this.$store.state.customer_module.filter.amountStart
      this.filter.amountEnd = this.$store.state.customer_module.filter.amountEnd
    },
    searchCustomer() {
      this.fetchCustomerList();
    },
    print() {
      this.$store.dispatch("customer_module/printCustomer", {
        customerId: this.filter.customerId == undefined || null ? "" : this.filter.customerId,
        name: this.filter.customerName == undefined || null ? "" :this.filter.customerName,
        timeStart: this.filter.timeuseStart == undefined || null ? "" :this.filter.timeuseStart,
        timeEnd: this.filter.timeuseEnd == undefined || null ? "" :this.filter.timeuseEnd,
        amountStart: this.filter.amountStart == undefined || null ? "" :this.filter.amountStart,
        amountEnd: this.filter.amountEnd == undefined || null ? "" :this.filter.amountEnd,
        lastOrder: this.filter.date == undefined || null ? "" :this.filter.date,
      });
    },

  },

  created() {
    this.initFilter()
  },
};
</script>

<style>
.filter-input-class .v-input__slot .v-text-field__slot input{
  padding: 8px 0px 8px 0px;
}
.filter-input-class .v-input__control .v-select__slot .v-select__selections input {
  padding: 8px 0px 8px 0px;
}
.filter-input-class .v-input__control .v-select__slot input {
  padding: 8px 0px 8px 0px;
}
.font-size {
  font-size: 12px !important;
}
.btn-search {
  border-radius: 20px;
}
.v-input .v-input__control .v-text-field__details {
  /* display: none; */
}
.v-select.v-text-field--outlined:not(.v-text-field--single-line)
  .v-select__selections {
  padding: 0px !important;
}
.v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 0px !important;
  align-self: center !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 0px !important;
  align-self: center !important;
}
.minus-amount {
  max-width: fit-content;
}
.minus-amount .icon-tilde-class {
  font-size: 14px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 8px;
}
</style>
