<template>
  <v-container>
    <FilterCustomer />
    <!-- <p>{{customerList}}</p> -->
    <!-- {{page}} -->
    <v-pagination
      v-if="!isLength"
      v-model="page"
      :length="lengthPage"
      :total-visible="7"
      @input="changePage"
      depressed
    ></v-pagination>

    <v-data-table
      :headers="headers"
      :items="customerList"
      disable-sort
      :hide-default-footer="true"
      :hide-default-header="true"
      :server-items-length="itemTotal"
      :options.sync="option"
      :page="currentPage"
      :items-per-page="itemPerPage"
      class="elavation-0"
      :footer-props="{ 'items-per-page-options': [itemPerPage] }"
    >
    <template v-slot:header="{ props }" style="min-height: 50px">
        <th class="custom-th text-center">
          {{ props.headers[0].text }}
        </th>
        <th class="custom-th text-center">
          {{ props.headers[1].text }}
        </th>
        <th class="custom-th text-center">
          {{ props.headers[2].text }}
        </th>
        <th class="custom-th text-center" >
          {{ props.headers[3].text }}
        </th>
        <th class="custom-th text-center">
          {{ props.headers[4].text }}
        </th>
        <th class="custom-th text-center">
          {{ props.headers[5].text }}
        </th>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <a href="" @click.prevent="toCustomerDetail(item.id)">{{ $t("message.detail") }}</a>
      </template>

    </v-data-table>
  </v-container>
</template>

<script>
import FilterCustomer from "@/components/FilterCustomer";
import moneyFormat from "../helpers/moneyFormat";
export default {
  name: "CustomerList",
  components: {
    FilterCustomer,
  },
  data: () => ({
    page: 1,
    option: {},
  }),
  watch: {
    customerList: {
      handler() {
        this.page = parseInt(this.$store.state.customer_module.page)
      },
      deep: true,
    },
  },
  computed: {
    isLength() {
      return this.$store.state.customer_module.is_length;
    },
    lengthPage() {
      return this.$store.state.customer_module.length_page;
    },
    customerList() {
      let customerData = this.$store.state.customer_module.customerListResult;

      if (!customerData) {
        return 0;
      } else {
        customerData.map((el) => {
          el.total_amount = moneyFormat(parseInt(el.total_amount));
        });

      }
      return customerData;
    },
    itemTotal() {
      let resp = this.$store.state.customer_module.customerHeaderResult;
      if (resp.total === undefined) {
        return 0;
      }
      return resp.total;
    },
    itemPerPage() {
      let resp = this.$store.state.customer_module.customerHeaderResult;
      if (resp.per_page === undefined) {
        return 1;
      }
      return resp.per_page;
    },
    currentPage() {
      let resp = this.$store.state.customer_module.customerHeaderResult;
      if (resp.current_page === undefined) {
        return 1;
      }
      return resp.current_page;
    },
    headers() {
      return [
        { text: this.$t("message.id"), align: "center", value: "id" },
        { text: this.$t("message.name"), align: "center", value: "customer_name" },
        { text: this.$t("message.time-use"),align: "center", value: "time_use" },
        { text: this.$t("message.total-amount"),align: "center", value: "total_amount" },
        { text: this.$t("message.order-date"),align: "center", value: "last_order_date" },
        { text: this.$t("message.action"),align: "center", value: "actions" },
      ];
    },
  },
  created() {
    this.dispatchCustomerList();
  },
  methods: {
    toCustomerDetail(id) {
      this.$router.push(`/customer/detail/${id}`);
    },
    dispatchCustomerList() {
      const { page } = this.option;
      let pageInit = parseInt(this.$store.state.customer_module.page) 
      this.page = parseInt(this.$store.state.customer_module.page) 
      
      return this.$store.dispatch("customer_module/fetchCustomerList", { page: pageInit });
    },
    changePage(event) {
      
      return this.$store.dispatch("customer_module/fetchCustomerList", {
        page: event,
      });
    },
  },
};
</script>

<style scoped>
.custom-th {
  background: #3949ab !important;
  padding: 12px;
  color: white;
}
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}
.v-application .text-start,
tr td {
  text-align: center !important;
}
thead tr th {
  color: white !important;
  font-size: 15px !important;
}
.v-data-footer-top {
  border-top: none !important;
}
.align-text-center {
  text-align: center !important;
  vertical-align: middle !important;
}
</style>
